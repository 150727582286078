.experience {
  padding: 2rem;
  border: 1px rgba(43, 27, 23, .5); ;
  border-radius: .25em;
  background-color: rgba(29, 29, 29, .5);
  width: 100%;
  text-align: center;
  align-items: center;
  vertical-align: middle;
}
.logo {
  margin: 1rem auto 1rem auto;
  background-color: white;
}