.accordion-item {
  padding: 1em;;
  border: 1px grey solid;
  border-radius: .25rem;
}

.accordion-head {
  display: flex;
  font-weight: bold;
}

.accordion-head .chevron:hover {
  cursor: pointer;
}

.accordion-body {
  background-image: linear-gradient(to left, rgba(255,0,0,0), rgba(255,0,0,1));
}